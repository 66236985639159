import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import i18n from "i18next";

const resources = {
  en: {
    "PrivacyPage.title": "Privacy Policy",
    "PrivacyPage.lastUpdated": "Last Updated: November 4, 2024",
    "PrivacyPage.sections": {
      collection: {
        title: "1. Information We Collect",
        content:
          "We collect information that you provide directly to us, including name, email address, and website content. We also automatically collect certain information about your device and usage of our services.",
      },
      use: {
        title: "2. How We Use Your Information",
        content:
          "We use the information we collect to provide and improve our services, communicate with you, and ensure security of our platform.",
      },
      sharing: {
        title: "3. Information Sharing",
        content:
          "We do not sell your personal information. We may share your information with service providers who assist in our operations.",
      },
      rights: {
        title: "4. Your Rights (GDPR & CCPA)",
        content:
          "You have the right to access, correct, or delete your personal information. EU and California residents have additional rights under applicable laws.",
      },
      security: {
        title: "5. Security",
        content:
          "We implement appropriate technical and organizational measures to protect your personal information.",
      },
    },
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const PrivacyPage: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`min-h-screen ${
        theme === "light" ? "bg-white text-black" : "bg-black text-white"
      }`}
    >
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-4">{t("PrivacyPage.title")}</h1>
        <p className="text-gray-500 mb-8">{t("PrivacyPage.lastUpdated")}</p>

        {Object.entries(t("PrivacyPage.sections", { returnObjects: true })).map(
          ([key, section]) => (
            <div key={key} className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                {(section as { title: string }).title}
              </h2>
              <p className="leading-relaxed">
                {(section as { content: string }).content}
              </p>
            </div>
          ),
        )}

        <div className="mt-16 pt-8 border-t border-gray-200">
          <Link
            to="/"
            className={`inline-block px-6 py-3 rounded-full ${
              theme === "light"
                ? "bg-black text-white hover:bg-gray-800"
                : "bg-white text-black hover:bg-gray-200"
            } transition duration-300`}
          >
            Back to Home
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default PrivacyPage;
