import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useRotatingWord } from "../../hooks/useRotatingWord";
import i18n from "i18next";

const resources = {
  en: {
    "HeroHeading.text1": "The simplest way",
    "HeroHeading.text2": "to create a website",
    "HeroHeading.adjectives": ["beautiful", "stunning", "inspiring"],
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const HeroHeading: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const adjectives = t("HeroHeading.adjectives", {
    returnObjects: true,
  }) as string[];
  const { currentWord, isTransitioning } = useRotatingWord(adjectives);

  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  const wordAnimation = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: -20, opacity: 0 },
    transition: { duration: 0.5, ease: "easeInOut" },
  };

  return (
    <motion.h1
      className={`elem13 box-border font-bold leading-[1.175em] ${
        theme === "light" ? "text-black" : "text-white"
      } mobile:text-3xl mt-[0] mobile:mb-[12px] mx-[0] tablet:text-6xl tablet:mb-[16px] laptop:text-7xl desktop:text-7xl laptop:mb-[16px] desktop:mb-[16px] max-w-[900px] mx-auto`}
      variants={container}
      initial="hidden"
      animate="visible"
      style={{
        textShadow:
          theme === "light"
            ? "1px 1px 2px rgba(0,0,0,0.1)"
            : "1px 1px 2px rgba(255,255,255,0.1)",
      }}
    >
      <div className="max-w-4xl mx-auto">
        <div className="flex flex-wrap justify-center items-center flex-col">
          <motion.span variants={item} className="inline-block">
            {t("HeroHeading.text1")}
          </motion.span>
          <div className="hidden">
            <div className="absolute left-0 top-0 h-full flex items-center justify-center w-full">
              <AnimatePresence mode="wait">
                <motion.span
                  key={currentWord}
                  className="inline-block"
                  {...wordAnimation}
                >
                  {currentWord}
                </motion.span>
              </AnimatePresence>
            </div>
          </div>
          <motion.span variants={item} className="inline-block mt-2">
            {t("HeroHeading.text2")}
          </motion.span>
        </div>
      </div>
    </motion.h1>
  );
};

export default HeroHeading;
