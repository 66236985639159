import React, { useState, useCallback } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FaXTwitter, FaDiscord } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import i18n from "i18next";
import { scrollToSection } from "../../utils/scroll";

const resources = {
  en: {
    "Footer.madeWith": "Made with CreateSite.ai",
    "Footer.product": "Product",
    "Footer.demo": "Demo",
    "Footer.pricing": "Pricing",
    "Footer.company": "Company",
    "Footer.about": "About",
    "Footer.blog": "Blog",
    "Footer.contact": "Contact",
    "Footer.legal": "Legal",
    "Footer.privacy": "Privacy Policy",
    "Footer.terms": "Terms of Service",
    "Footer.copyright": "© 2024 CreateSite.ai. All rights reserved.",
    "Footer.social": "Social",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const Footer: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [showEmail, setShowEmail] = useState(false);

  const handleScroll = (elementId: string) => {
    scrollToSection(elementId);
  };

  const handleExternalLink = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // Email protection using string concatenation
  const getProtectedEmail = useCallback(() => {
    const parts = ["thomas", "@", "createsite", ".", "ai"];
    return parts.join("");
  }, []);

  const handleContactClick = () => {
    setShowEmail(true);
  };

  return (
    <footer
      className={`py-16 ${
        theme === "light" ? "bg-white text-black" : "bg-black text-white"
      } text-base mobile:text-base tablet:text-lg laptop:text-lg desktop:text-lg border-t ${
        theme === "light" ? "border-gray-100" : "border-gray-800"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid mobile:grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 desktop:grid-cols-4 gap-8 mb-12">
          {/* Product Column */}
          <div className="flex flex-col items-center text-center">
            <h3 className="font-bold mb-4">{t("Footer.product")}</h3>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => handleScroll("demo-section")}
                  className="hover:opacity-70 transition-opacity"
                >
                  {t("Footer.demo")}
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleScroll("pricing-section")}
                  className="hover:opacity-70 transition-opacity"
                >
                  {t("Footer.pricing")}
                </button>
              </li>
            </ul>
          </div>

          {/* Company Column */}
          <div className="flex flex-col items-center text-center">
            <h3 className="font-bold mb-4">{t("Footer.company")}</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/blog/introducing-createsite-ai"
                  className="hover:opacity-70 transition-opacity"
                >
                  {t("Footer.blog")}
                </Link>
              </li>
              <li>
                <AnimatePresence mode="wait">
                  {showEmail ? (
                    <motion.span
                      initial={{ opacity: 0 }}
                      transition={{ duration: 1.5 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1.5 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1.5 }}
                      className="hover:opacity-70 transition-opacity cursor-pointer"
                      onClick={() =>
                        handleExternalLink(`mailto:${getProtectedEmail()}`)
                      }
                    >
                      {getProtectedEmail()}
                    </motion.span>
                  ) : (
                    <motion.button
                      initial={{ opacity: 1 }}
                      transition={{ duration: 1.5 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1.5 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1.5 }}
                      onClick={handleContactClick}
                      className="hover:opacity-70 transition-opacity"
                    >
                      {t("Footer.contact")}
                    </motion.button>
                  )}
                </AnimatePresence>
              </li>
            </ul>
          </div>

          {/* Legal Column */}
          <div className="flex flex-col items-center text-center">
            <h3 className="font-bold mb-4">{t("Footer.legal")}</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/privacy"
                  className="hover:opacity-70 transition-opacity"
                >
                  {t("Footer.privacy")}
                </Link>
              </li>
              <li>
                <Link
                  to="/terms"
                  className="hover:opacity-70 transition-opacity"
                >
                  {t("Footer.terms")}
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Column */}
          <div className="flex flex-col items-center text-center">
            <h3 className="font-bold mb-4">{t("Footer.social")}</h3>
            <div className="flex items-center gap-4 mb-4">
              <button
                onClick={() =>
                  handleExternalLink("https://x.com/thomascloarecAI")
                }
                className="hover:opacity-70 transition-opacity"
                aria-label="Twitter"
              >
                <FaXTwitter className="w-5 h-5" />
              </button>
              <button
                onClick={() =>
                  handleExternalLink(
                    "https://linkedin.com/company/createsiteai",
                  )
                }
                className="hover:opacity-70 transition-opacity"
                aria-label="LinkedIn"
              >
                <FaLinkedin className="w-5 h-5" />
              </button>
              <button
                onClick={() =>
                  handleExternalLink("https://discord.gg/RjXuYbf9cM")
                }
                className="hover:opacity-70 transition-opacity"
                aria-label="Discord"
              >
                <FaDiscord className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        <div className="border-t border-opacity-10 pt-8 flex flex-col items-center gap-4 text-center">
          <p>{t("Footer.madeWith")}</p>
          <p className="text-sm opacity-70">{t("Footer.copyright")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
