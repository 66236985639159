import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const resources = {
  en: {
    "HeaderLogo.demo": "Demo",
    "HeaderLogo.pricing": "Pricing",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const HeaderLogo: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const handleScroll = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      const navbarHeight = 80; // Height of the sticky navbar
      element.scrollIntoView({ behavior: "smooth" });
      // Adjust scroll position to account for navbar height
      setTimeout(() => {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - navbarHeight,
          behavior: "smooth",
        });
      }, 0);
    }
  };

  return (
    <div
      className="elem53 box-border flex items-center mobile:mt-8 tablet:mt-8"
      original-class="header-left-side"
    >
      <Link
        aria-current="page"
        className={`elem3 bg-transparent box-border ${
          theme === "light" ? "text-black" : "text-white"
        } no-underline relative mobile:text-xl mobile:mr-4 pl-0 active:outline-none tablet:mr-10 tablet:text-2xl laptop:mr-10 laptop:text-2xl desktop:mr-10 desktop:text-2xl cursor-default`}
        to="#"
        onClick={(e) => e.preventDefault()}
        original-class="header-logo-link left w-nav-brand w--current"
      >
        <span
          className={`text-2xl font-bold ${
            theme === "light" ? "text-black" : "text-white"
          } flex items-center w-full justify-between`}
        >
          <span>CreateSite.ai</span>
        </span>
      </Link>
      <div className="flex items-center gap-8 mobile:hidden tablet:flex laptop:flex desktop:flex">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleScroll("demo-section");
          }}
          className={`text-lg font-medium transition-colors duration-300 ${
            theme === "light"
              ? "text-black hover:text-gray-600"
              : "text-white hover:text-gray-300"
          }`}
        >
          {t("HeaderLogo.demo")}
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleScroll("pricing-section");
          }}
          className={`text-lg font-medium transition-colors duration-300 ${
            theme === "light"
              ? "text-black hover:text-gray-600"
              : "text-white hover:text-gray-300"
          }`}
        >
          {t("HeaderLogo.pricing")}
        </a>
      </div>
    </div>
  );
};

export default HeaderLogo;
