import { useEffect, RefObject } from "react";
import { createConfetti } from "./confettiUtils";

const useConfettiEffect = (confettiRef: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const confettiInterval = setInterval(() => {
      if (confettiRef.current) {
        const x = Math.random() * confettiRef.current.clientWidth;
        const y = Math.random() * confettiRef.current.clientHeight;
        createConfetti(confettiRef.current, x, y);
      }
    }, 200);

    return () => clearInterval(confettiInterval);
  }, [confettiRef]);
};

export default useConfettiEffect;
