import React from "react";
import HeaderLogo from "./HeaderLogo";
import HeaderActions from "./HeaderActions";
import { useTheme } from "../../contexts/ThemeContext";

interface HeaderProps {
  setBetaButtonVisible: (visible: boolean) => void;
  setShowContactModal: (show: boolean) => void;
}

function Header({ setBetaButtonVisible, setShowContactModal }: HeaderProps) {
  const { theme } = useTheme();

  return (
    <div
      className={`elem51 box-border max-w-[1268px] mx-auto mobile:px-4 tablet:px-6 laptop:px-6 desktop:px-6 before:content-['_'] before:table after:content-['_'] after:table after:clear-both ${
        theme === "light" ? "bg-transparent" : "bg-transparent"
      }`}
      original-class="container-default w-container"
    >
      <div
        className="elem52 opacity-100 box-border flex justify-between items-center w-full px-4"
        data-w-id="d507ab60-f113-b22c-b32d-66ec128d2ca7"
        original-class="header-content-wrapper"
      >
        <HeaderLogo />
        <HeaderActions setShowContactModal={setShowContactModal} />
      </div>
    </div>
  );
}

export default Header;
