import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";
import { useSignIn } from "../../contexts/SignInContext";
import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
import i18n from "i18next";
import { scrollToSection } from "../../utils/scroll";

const resources = {
  en: {
    "MobileMenu.signIn": "Sign In",
    "MobileMenu.getStarted": "Get Started",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const menuVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const contentVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      const scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, [isOpen]);

  const handleGetStarted = () => {
    const element = document.getElementById("pricing-section");
    if (element) {
      const offset = 60;
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
    onClose();
  };

  const handleScroll = (elementId: string) => {
    scrollToSection(elementId, true, onClose);
  };

  const handleSignIn = () => {
    window.open("https://app.createsite.ai", "_blank", "noopener,noreferrer");
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={menuVariants}
          className={`fixed inset-0 z-[9999] min-h-screen w-full ${
            theme === "light" ? "bg-white" : "bg-white"
          }`}
        >
          <motion.div
            variants={contentVariants}
            className="min-h-screen w-full flex flex-col"
          >
            <div className="flex items-center justify-between px-[32px] pt-[28px] pb-[36px]">
              <span
                className={`text-2xl font-bold ${
                  theme === "light" ? "text-black" : "text-black"
                }`}
              >
                CreateSite.ai
              </span>
              <button
                onClick={onClose}
                className={`p-2 -mr-2 ${theme === "light" ? "text-black" : "text-black"}`}
              >
                <FiX size={24} />
              </button>
            </div>

            <div className="flex-grow flex flex-col justify-center gap-6 px-6">
              <button
                onClick={() => {
                  handleScroll("demo-section");
                  onClose();
                }}
                className={`text-center py-4 text-xl ${
                  theme === "light" ? "text-black" : "text-white"
                } font-medium`}
              >
                {t("HeaderLogo.demo")}
              </button>
              <button
                onClick={() => {
                  handleScroll("pricing-section");
                  onClose();
                }}
                className={`text-center py-4 text-xl ${
                  theme === "light" ? "text-black" : "text-black"
                } font-medium`}
              >
                {t("HeaderLogo.pricing")}
              </button>
              <button
                onClick={handleSignIn}
                className={`text-center py-4 text-xl ${
                  theme === "light" ? "text-black" : "text-black"
                } font-medium`}
              >
                {t("MobileMenu.signIn")}
              </button>
              <button
                onClick={handleGetStarted}
                className={`w-full py-4 rounded-full text-xl font-medium ${
                  theme === "light"
                    ? "bg-black text-white"
                    : "bg-black text-white"
                }`}
              >
                {t("MobileMenu.getStarted")}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileMenu;
