import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import i18n from "i18next";

const resources = {
  en: {
    "TermsPage.title": "Terms of Service",
    "TermsPage.lastUpdated": "Last Updated: November 4, 2024",
    "TermsPage.sections": {
      acceptance: {
        title: "1. Acceptance of Terms",
        content:
          "By accessing and using CreateSite.ai, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.",
      },
      services: {
        title: "2. Services",
        content:
          "CreateSite.ai provides an AI-powered website creation platform. We reserve the right to modify, suspend, or discontinue any aspect of our services at any time.",
      },
      accounts: {
        title: "3. User Accounts",
        content:
          "You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
      },
      content: {
        title: "4. User Content",
        content:
          "You retain ownership of any content you upload to our services. However, you grant us a license to use, store, and display your content for the purpose of providing our services.",
      },
      restrictions: {
        title: "5. Usage Restrictions",
        content:
          "You agree not to use our services for any unlawful purpose or in violation of these terms.",
      },
    },
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const TermsPage: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`min-h-screen ${
        theme === "light" ? "bg-white text-black" : "bg-black text-white"
      }`}
    >
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-4">{t("TermsPage.title")}</h1>
        <p className="text-gray-500 mb-8">{t("TermsPage.lastUpdated")}</p>

        {Object.entries(t("TermsPage.sections", { returnObjects: true })).map(
          ([key, section]) => (
            <div key={key} className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                {(section as { title: string }).title}
              </h2>
              <p className="leading-relaxed">
                {(section as { content: string }).content}
              </p>
            </div>
          ),
        )}

        <div className="mt-16 pt-8 border-t border-gray-200">
          <Link
            to="/"
            className={`inline-block px-6 py-3 rounded-full ${
              theme === "light"
                ? "bg-black text-white hover:bg-gray-800"
                : "bg-white text-black hover:bg-gray-200"
            } transition duration-300`}
          >
            Back to Home
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default TermsPage;
