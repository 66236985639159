import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";
import { gsap } from "gsap";

const LoadingSkeleton: React.FC = () => {
  const { theme } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const createSparkle = () => {
      if (!containerRef.current) return;

      const sparkle = document.createElement("div");
      sparkle.className = "absolute w-1 h-1 rounded-full";
      sparkle.style.backgroundColor = theme === "light" ? "#ffffff" : "#404040";

      // Random position within container
      const x = Math.random() * containerRef.current.offsetWidth;
      const y = Math.random() * containerRef.current.offsetHeight;
      sparkle.style.left = `${x}px`;
      sparkle.style.top = `${y}px`;

      containerRef.current.appendChild(sparkle);

      // Animate the sparkle
      gsap.to(sparkle, {
        scale: [0, 1, 0],
        opacity: [0, 1, 0],
        duration: 1.5,
        ease: "power2.inOut",
        onComplete: () => {
          if (containerRef.current?.contains(sparkle)) {
            containerRef.current.removeChild(sparkle);
          }
        },
      });
    };

    // Create sparkles at intervals
    const interval = setInterval(() => {
      for (let i = 0; i < 3; i++) {
        setTimeout(createSparkle, i * 200);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, [theme]);

  return (
    <div
      ref={containerRef}
      className={`absolute inset-0 w-full mobile:h-[240px] tablet:h-[440px] laptop:h-[440px] desktop:h-[440px] rounded-lg overflow-hidden ${
        theme === "light" ? "bg-gray-200" : "bg-[#1a1a1a]"
      }`}
    >
      <motion.div
        className="absolute inset-0 w-full h-full"
        style={{
          background:
            theme === "light"
              ? "linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.4) 50%, transparent 100%)"
              : "linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.1) 50%, transparent 100%)",
        }}
        animate={{
          x: ["-100%", "100%"],
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: "linear",
        }}
      />
    </div>
  );
};

export default LoadingSkeleton;
