import { useEffect, useState, RefObject } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { createConfetti } from "./confettiUtils";

gsap.registerPlugin(ScrollTrigger);

const useGrowingImageEffects = (
  buttonRef: RefObject<HTMLButtonElement>,
  confettiRef: RefObject<HTMLDivElement>,
) => {
  const [isHovering, setIsHovering] = useState(false);
  const [confettiInterval, setConfettiInterval] =
    useState<NodeJS.Timeout | null>(null);
  const [hasTriggeredBurst, setHasTriggeredBurst] = useState(false);

  useEffect(() => {
    if (buttonRef.current) {
      gsap.fromTo(
        buttonRef.current,
        {
          y: "100%",
          opacity: 0,
        },
        {
          y: "0%",
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          scrollTrigger: {
            trigger: buttonRef.current,
            start: "top bottom-=100",
            end: "bottom bottom",
            scrub: 1,
            onEnter: () => gsap.to(buttonRef.current, { opacity: 1 }),
            onLeaveBack: () => gsap.to(buttonRef.current, { opacity: 0 }),
          },
        },
      );
    }

    ScrollTrigger.create({
      trigger: document.body,
      start: "bottom bottom",
      end: "bottom bottom",
      onEnter: () => {
        if (buttonRef.current) {
          gsap.to(buttonRef.current, { opacity: 1, duration: 0.3 });
        }
      },
      onLeaveBack: () => {
        if (buttonRef.current) {
          gsap.to(buttonRef.current, { opacity: 1, duration: 0.3 });
        }
      },
    });
  }, []);

  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsHovering(true);
    if (!hasTriggeredBurst && confettiRef.current) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      createConfetti(confettiRef.current, x, y, true);
      setHasTriggeredBurst(true);
    }

    const interval = setInterval(() => {
      if (confettiRef.current) {
        const x = Math.random() * confettiRef.current.clientWidth;
        const y = Math.random() * confettiRef.current.clientHeight;
        createConfetti(confettiRef.current, x, y);
      }
    }, 50);

    setConfettiInterval(interval);

    gsap.to(buttonRef.current, {
      scale: 1.05,
      duration: 0.5,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
    });
  };

  const handleMouseLeave = () => {
    setIsHovering(false);

    if (confettiInterval) {
      clearInterval(confettiInterval);
    }

    gsap.to(buttonRef.current, {
      scale: 1,
      duration: 0.3,
      ease: "power1.inOut",
      onComplete: () => {
        gsap.killTweensOf(buttonRef.current);
      },
    });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isHovering && confettiRef.current) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      createConfetti(confettiRef.current, x, y);
    }
  };

  return { handleMouseEnter, handleMouseLeave, handleMouseMove };
};

export default useGrowingImageEffects;
