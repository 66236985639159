import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { FaDiscord } from "react-icons/fa";
import { useTheme } from "../../contexts/ThemeContext";
import { useSignIn } from "../../contexts/SignInContext";
import { useTranslation } from "react-i18next";
import MobileMenu from "./MobileMenu";
import i18n from "i18next";

const resources = {
  en: {
    "HeaderActions.signIn": "Sign In",
    "HeaderActions.getStarted": "Get Started",
    "HeaderActions.toggleTheme": "Toggle theme",
    "HeaderActions.discord": "Join our Discord server",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

interface HeaderActionsProps {
  setShowContactModal: (show: boolean) => void;
}

const HeaderActions: React.FC<HeaderActionsProps> = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { theme } = useTheme();
  const { t } = useTranslation();

  const handleGetStarted = () => {
    window.open("https://app.createsite.ai", "_blank", "noopener,noreferrer");
  };

  const handleSignIn = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open("https://app.createsite.ai", "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
      />
      <div className="flex justify-end items-center gap-8 mobile:gap-2 mobile:mt-8 tablet:mt-8">
        <button
          onClick={() => setIsMobileMenuOpen(true)}
          className="mobile:block tablet:hidden laptop:hidden desktop:hidden text-2xl"
        >
          <FiMenu />
        </button>
        <button
          onClick={() =>
            window.open(
              "https://discord.gg/RjXuYbf9cM",
              "_blank",
              "noopener,noreferrer",
            )
          }
          className={`hidden mobile:hidden tablet:block laptop:block desktop:block p-2 rounded-full transition-colors duration-300 transition-colors duration-300 hover:opacity-70 ${
            theme === "light" ? "text-black" : "text-white"
          }`}
          aria-label={t("HeaderActions.discord")}
        >
          <FaDiscord className="w-6 h-6" />
        </button>
        <button
          onClick={handleSignIn}
          className={`hidden mobile:hidden tablet:block laptop:block desktop:block text-lg font-medium transition-colors duration-300 ${
            theme === "light"
              ? "text-black hover:text-gray-600"
              : "text-white hover:text-gray-300"
          }`}
        >
          {t("HeaderActions.signIn")}
        </button>
        <button
          onClick={handleGetStarted}
          className={`mobile:hidden px-4 py-2 rounded-full text-lg font-medium transition-all duration-300 ${
            theme === "light"
              ? "bg-black text-white hover:bg-opacity-80"
              : "bg-white text-black hover:bg-opacity-80"
          }`}
        >
          <span>{t("HeaderActions.getStarted")}</span>
        </button>
      </div>
    </>
  );
};

export default HeaderActions;
