import { gsap } from "gsap";

export const createConfetti = (
  container: HTMLDivElement,
  x: number,
  y: number,
  isBurst: boolean = false,
) => {
  const confettiCount = isBurst ? 50 : 1;
  const confettiElements: HTMLDivElement[] = [];

  for (let i = 0; i < confettiCount; i++) {
    const confetti = document.createElement("div");
    confetti.className = "absolute w-2 h-1";
    confetti.style.left = `${x}px`;
    confetti.style.top = `${y}px`;
    confetti.style.backgroundColor = getRandomColor();
    container.appendChild(confetti);
    confettiElements.push(confetti);
  }

  gsap.to(confettiElements, {
    x: () => gsap.utils.random(-300, 300),
    y: () => gsap.utils.random(-300, 300),
    rotation: gsap.utils.random(-180, 180),
    opacity: 0,
    duration: isBurst ? 4 : 2,
    ease: "power2.out",
    stagger: isBurst ? 0.02 : 0,
    onComplete: () => {
      confettiElements.forEach((el) => container.removeChild(el));
    },
  });
};

export const getRandomColor = () => {
  const colors = [
    "#3099ff",
    "#f1f1f4",
    "#050719",
    "#525566",
    "#FF69B4",
    "#39FF14",
    "#00FFFF",
    "#FF1493",
    "#7CFC00",
    "#1E90FF",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};
