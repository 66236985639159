import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const resources = {
  en: {
    "SuccessMessage.title": "Thank you for sharing your vision!",
    "SuccessMessage.message":
      "We'll review your idea and get back to you soon.",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const SuccessMessage: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h2
        className={`text-2xl font-bold mb-4 ${
          theme === "light" ? "text-black" : "text-white"
        }`}
      >
        {t("SuccessMessage.title")}
      </h2>
      <p
        className={`mb-6 ${
          theme === "light" ? "text-gray-700" : "text-gray-300"
        }`}
      >
        {t("SuccessMessage.message")}
      </p>
    </div>
  );
};

export default SuccessMessage;
