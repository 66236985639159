import React, { useState, useEffect } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTheme } from "../../contexts/ThemeContext";
import { motion, AnimatePresence } from "framer-motion";
import Header from "../../components/Header";
import HeroSection from "../../components/HeroSection";
import ProductDemo from "../../components/ProductDemo";
import GrowingImage from "../../components/GrowingImage";
import FullScreenSection from "../../components/FullScreenSection";
import FloatingBetaButton from "../../components/FloatingBetaButton";
import Footer from "../../components/Footer";
import FloatingAnnouncement from "../../components/FloatingAnnouncement";
import PricingSection from "../../components/PricingSection";
import Testimonials from "../../components/Testimonials";
import { useHomeAnimations } from "./useHomeAnimations";

interface HomeProps {
  setShowEmailModal: (show: boolean) => void;
  setShowContactModal: (show: boolean) => void;
}

function Home({ setShowEmailModal, setShowContactModal }: HomeProps) {
  useHomeAnimations();
  const [isBetaExpanded, setIsBetaExpanded] = useState(false);
  const [isBetaButtonVisible, setBetaButtonVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { theme } = useTheme();

  const handleBetaExpand = () => {
    setIsBetaExpanded(true);
  };

  // Ensure smooth initial render
  useEffect(() => {
    // Small delay to ensure all components are ready
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return null; // Return null during initial load to prevent flash
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, ease: "easeOut" }}
      className={`elem49 box-border relative overflow-x-hidden ${
        theme === "light" ? "bg-white text-black" : "bg-black text-white"
      }`}
      original-class="page-wrapper"
    >
      <FloatingBetaButton
        onExpand={handleBetaExpand}
        isVisible={isBetaButtonVisible}
      />
      <div
        className={`elem50 box-border z-[999] bg-[image:none] bg-repeat bg-[position:left_top] bg-[size:auto] bg-scroll bg-origin-padding bg-clip-border ${
          theme === "light" ? "bg-white" : "bg-[color:rgba(0,0,0,0)]"
        } fixed mobile:pt-0 mobile:pb-[24px] tablet:pt-0 tablet:pb-[24px] top-[0%] bottom-auto inset-x-[0%] backdrop-blur-md bg-opacity-80 before:content-['_'] before:table after:content-['_'] after:table after:clear-both laptop:py-[32px] desktop:py-[32px]`}
        data-animation="default"
        data-collapse="medium"
        data-duration={400}
        data-easing="ease"
        data-easing2="ease"
        data-w-id="d507ab60-f113-b22c-b32d-66ec128d2ca5"
        original-class="header-wrapper w-nav"
        role="banner"
      >
        <Header
          setBetaButtonVisible={setBetaButtonVisible}
          setShowContactModal={setShowContactModal}
        />
        <div
          className="elem99 box-border w-[100%] hidden absolute overflow-hidden top-full inset-x-[0]"
          data-wf-ignore=""
          id="w-nav-overlay-0"
          original-class="w-nav-overlay"
        />
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.5 }}
        >
          <HeroSection setShowEmailModal={setShowEmailModal} />
          <ProductDemo />
          <Testimonials />
          <PricingSection />
          <FullScreenSection>
            <GrowingImage />
          </FullScreenSection>
          <FloatingAnnouncement />
          <Footer />
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
}

export default Home;
