import React from "react";
import { useTheme } from "../../contexts/ThemeContext";

interface IconButtonProps {
  variant?: "navigation" | "action";
  icon: React.ReactNode;
  onClick?: () => void;
  ariaLabel?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  variant = "action",
  icon,
  onClick,
  ariaLabel,
}) => {
  const { theme } = useTheme();

  return (
    <button
      onClick={onClick}
      aria-label={ariaLabel}
      className={`p-1.5 rounded-full transition-all duration-200 cursor-pointer hover:opacity-60`}
    >
      <span
        className={`${
          variant === "navigation"
            ? theme === "light"
              ? "text-gray-400"
              : "text-gray-300"
            : theme === "light"
              ? "text-gray-600"
              : "text-gray-400"
        }`}
      >
        {icon}
      </span>
    </button>
  );
};

export default IconButton;
