import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { UseContactModalReturn } from "./types";

const useContactModal = (
  isOpen: boolean,
  onClose: () => void,
): UseContactModalReturn => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && modalRef.current && modalContentRef.current) {
      gsap.set(modalRef.current, { opacity: 0 });
      gsap.set(modalContentRef.current, { scale: 0.9, opacity: 0 });

      gsap.to(modalRef.current, {
        opacity: 1,
        duration: 0.3,
        ease: "power2.inOut",
      });

      gsap.to(modalContentRef.current, {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        ease: "back.out(1.7)",
      });
    }
  }, [isOpen]);

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === e.target) {
      handleClose();
    }
  };

  const handleClose = () => {
    if (modalRef.current && modalContentRef.current) {
      gsap.to(modalRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.inOut",
      });

      gsap.to(modalContentRef.current, {
        scale: 0.9,
        opacity: 0,
        duration: 0.3,
        ease: "power2.in",
        onComplete: onClose,
      });
    }
  };

  return { modalRef, modalContentRef, handleOutsideClick };
};

export default useContactModal;
