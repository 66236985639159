import React from "react";
import { useTheme } from "../../contexts/ThemeContext";

interface ModalContentProps {
  children: React.ReactNode;
}

const ModalContent: React.FC<ModalContentProps> = ({ children }) => {
  const { theme } = useTheme();

  return (
    <div
      className={`${
        theme === "light" ? "bg-white text-black" : "bg-black text-white"
      } p-8 rounded-lg shadow-xl max-w-md w-full`}
    >
      {children}
    </div>
  );
};

export default ModalContent;
