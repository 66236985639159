import { gsap } from "gsap";
import { useEffect } from "react";

export function useDataWIdAnimation30() {
  useEffect(() => {
    gsap.set('[data-w-id="38bf096c-2a7d-36dd-0956-db0fb6343743"]', {"y": "10%"});
    gsap.set('[data-w-id="38bf096c-2a7d-36dd-0956-db0fb6343743"]', {"opacity": 0});
                const tl = gsap.timeline({"scrollTrigger": {"trigger": "[data-w-id=\"38bf096c-2a7d-36dd-0956-db0fb6343743\"]", "start": "top 70%", "end": "bottom top", "toggleActions": "play none none none", "once": true}});
      tl.addLabel("label0");
      tl.to('[data-w-id="38bf096c-2a7d-36dd-0956-db0fb6343743"]', {"duration": 0.5, "delay": 0.2, "ease": "power1.inOut", "y": "0%"}, "label0");
      tl.to('[data-w-id="38bf096c-2a7d-36dd-0956-db0fb6343743"]', {"duration": 0.5, "delay": 0.2, "ease": "power1.inOut", "opacity": 1}, "label0");
    return () => {
      tl.kill();
    };
  }, []);
}