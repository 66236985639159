import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";
import IconButton from "./IconButton";
import {
  FiArrowLeft,
  FiArrowRight,
  FiRotateCw,
  FiExternalLink,
} from "react-icons/fi";

interface BrowserWindowProps {
  children: React.ReactNode;
  url?: string;
  opacity?: number;
}

const BrowserWindow: React.FC<BrowserWindowProps> = ({
  children,
  url = "https://example.com",
  opacity = 1,
}) => {
  const { theme } = useTheme();
  const handleOpenUrl = (e: React.MouseEvent) => {
    e.preventDefault();
    if (url === "pyrrha") {
      window.open("https://pyrrha.fr", "_blank", "noopener,noreferrer");
    } else if (url === "freeda") {
      window.open(
        "https://31232aa2-2b9c-43e7-a514-fbd48e4281f5.vercel.app/",
        "_blank",
        "noopener,noreferrer",
      );
    } else if (url && url.startsWith("http")) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else if (url !== "freeda.so") {
      window.open(`https://${url}`, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <motion.div
      className={`rounded-lg overflow-hidden ${
        theme === "light" ? "bg-gray-100" : "bg-[#1a1a1a]"
      } shadow-lg`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Browser Controls */}
      <div
        className={`px-4 mobile:py-3 tablet:py-4 laptop:py-4 desktop:py-4 pointer-events-none select-none ${
          theme === "light" ? "bg-gray-200" : "bg-[#2a2a2a]"
        } flex items-center space-x-2`}
      >
        {/* Navigation Controls */}
        <div className="flex items-center space-x-2">
          <IconButton
            icon={<FiArrowLeft />}
            ariaLabel="Go back"
            variant="navigation"
          />
          <IconButton
            icon={<FiArrowRight />}
            ariaLabel="Go forward"
            variant="navigation"
          />
          <IconButton
            icon={<FiRotateCw />}
            ariaLabel="Reload"
            variant="navigation"
          />
        </div>

        {/* URL Bar */}
        <div
          className={`flex-1 mx-4 px-3 py-1 rounded-lg ${
            theme === "light" ? "bg-white" : "bg-[#1a1a1a]"
          } mobile:text-sm tablet:text-base laptop:text-base desktop:text-base flex items-center`}
        >
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity }}
            transition={{ duration: 0.8 }}
            className={`${
              theme === "light" ? "text-gray-600" : "text-gray-400"
            }`}
          >
            {url}
          </motion.span>
        </div>
        <div className="pointer-events-auto">
          <IconButton
            icon={<FiExternalLink />}
            onClick={(e) => handleOpenUrl(e)}
            ariaLabel="Open in new tab"
          />
        </div>
      </div>

      {/* Content */}
      <motion.div
        className="relative"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default BrowserWindow;
