import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";

interface WaveButtonProps {
  type: "submit";
  disabled: boolean;
  theme: "light" | "dark";
  submitting: boolean;
  children: React.ReactNode;
  fillPercentage: number;
}

const WaveButton: React.FC<WaveButtonProps> = ({
  type,
  disabled,
  theme,
  submitting,
  children,
  fillPercentage,
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const sparkleRef = useRef<HTMLSpanElement>(null);
  const [textPixels, setTextPixels] = useState<HTMLSpanElement[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [sparkleOpacity, setSparkleOpacity] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      // Clear existing text content
      const text = textRef.current.textContent || "";
      textRef.current.textContent = "";

      // Create individual spans for each character
      const chars = text.split("").map((char, index) => {
        const span = document.createElement("span");
        span.textContent = char;
        span.style.display = "inline-block";
        span.style.transition = "color 0.2s ease";
        // Add small spacing between characters
        if (char === " ") {
          span.style.marginRight = "0.25rem";
        }
        textRef.current?.appendChild(span);
        return span;
      });

      setTextPixels(chars);
    }

    return () => {
      setTextPixels([]);
    };
  }, [children]);

  useEffect(() => {
    if (textPixels.length > 0) {
      textPixels.forEach((pixel, index) => {
        const pixelRect = pixel.getBoundingClientRect();
        const buttonRect =
          textRef.current?.parentElement?.getBoundingClientRect();

        if (buttonRect) {
          const pixelPosition =
            ((pixelRect.left - buttonRect.left + pixelRect.width / 2) /
              buttonRect.width) *
            100;
          const shouldBeLight = isHovered || pixelPosition < fillPercentage;

          pixel.style.color =
            theme === "light"
              ? shouldBeLight
                ? "#ffffff"
                : "#000000"
              : shouldBeLight
                ? "#000000"
                : "#ffffff";
        }
      });
    }
  }, [fillPercentage, theme, textPixels, isHovered]);

  useEffect(() => {
    if (sparkleRef.current) {
      const sparkleRect = sparkleRef.current.getBoundingClientRect();
      const buttonRect =
        sparkleRef.current.parentElement?.getBoundingClientRect();

      if (buttonRect) {
        const sparklePosition =
          ((sparkleRect.left - buttonRect.left) / buttonRect.width) * 100;
        const shouldBeVisible = isHovered || sparklePosition < fillPercentage;
        setSparkleOpacity(shouldBeVisible ? 1 : 0);
      }
    }
  }, [fillPercentage, isHovered]);

  return (
    <motion.button
      type={type}
      disabled={disabled}
      className={`relative w-full px-8 py-4 rounded-full overflow-hidden ${
        theme === "light"
          ? "border-black border text-black"
          : "border-white border text-white"
      } transition-colors duration-300 font-medium`}
      whileHover="hover"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`absolute inset-0 transition-all duration-1000 ease-out ${
          theme === "light" ? "bg-black" : "bg-white"
        }`}
        style={{
          width: isHovered ? "100%" : `${fillPercentage}%`,
          left: 0,
          top: 0,
          bottom: 0,
        }}
      />
      <motion.div
        className="absolute inset-0"
        initial={{ x: "-100%" }}
        variants={{
          hover: {
            x: "100%",
            transition: {
              duration: 1.5,
              ease: "easeInOut",
              repeat: Infinity,
            },
          },
        }}
        style={{
          background:
            theme === "light"
              ? "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)"
              : "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%)",
        }}
      />{" "}
      <span
        ref={textRef}
        className="relative z-10 transition-none pl-2 hero-description-text"
      >
        {children}
      </span>
      <span
        ref={sparkleRef}
        className="relative z-10 ml-1 transition-opacity duration-300"
        style={{ opacity: sparkleOpacity }}
      >
        ✨
      </span>
    </motion.button>
  );
};

export default WaveButton;
