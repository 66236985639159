import React, { useRef, useMemo, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { Points, PointMaterial } from "@react-three/drei";
import * as THREE from "three";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { gsap } from "gsap";

interface ParticleSystemProps {
  opacity: number;
  color: string;
}

const ParticleSystem: React.FC<ParticleSystemProps> = ({ opacity, color }) => {
  const ref = useRef<THREE.Points>(null);
  const particleCount = 4000;
  const scrollPosition = useScrollPosition();
  const maxScrollDepth = 1000;
  const materialRef = useRef<THREE.PointsMaterial>(null);

  const positions = useMemo(() => {
    const positions = new Float32Array(particleCount * 3);
    for (let i = 0; i < particleCount; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
    }
    return positions;
  }, [particleCount]);

  // Initial opacity animation
  useEffect(() => {
    if (materialRef.current) {
      gsap.fromTo(
        materialRef.current,
        { opacity: 0 },
        {
          opacity: opacity,
          duration: 1,
          ease: "power2.out",
        },
      );
    }
  }, [opacity]);

  useFrame((state) => {
    if (ref.current) {
      const time = state.clock.getElapsedTime();
      const scrollFactor = Math.min(scrollPosition / maxScrollDepth, 1);

      // Smoother 2D movement with easing
      ref.current.position.x = Math.sin(time * 0.2) * 0.15;
      ref.current.position.y = Math.cos(time * 0.2) * 0.15;

      // Smoother 3D rotation based on scroll with easing
      const targetRotationX = scrollFactor * Math.PI * 0.5;
      const targetRotationY = scrollFactor * Math.PI * 0.5;

      ref.current.rotation.x +=
        (targetRotationX - ref.current.rotation.x) * 0.05;
      ref.current.rotation.y +=
        (targetRotationY - ref.current.rotation.y) * 0.05;
    }
  });

  return (
    <Points ref={ref} positions={positions} stride={3}>
      <PointMaterial
        ref={materialRef}
        transparent
        color={color}
        size={0.009}
        sizeAttenuation={true}
        maxSize={0.045}
        depthWrite={false}
        opacity={0} // Start with 0 opacity, will be animated
        blending={THREE.AdditiveBlending}
      />
    </Points>
  );
};

export default ParticleSystem;
