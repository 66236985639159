import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const resources = {
  en: {
    "HeroDescription.text1":
      "Just write what you want, and AI builds your website.",
    "HeroDescription.text2": "When you need changes, write again. That's it.",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const HeroDescription: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div
      className="box-border max-w-[900px] mx-auto w-full"
      original-class="inner-container _574px _100-tablet"
    >
      <p
        className={`elem105 box-border hero-description-text ${
          theme === "light" ? "text-gray-700" : "text-[#f1f1f4]"
        } mt-[0] mobile:mb-[32px] tablet:mb-[40px] laptop:mb-[40px] desktop:mb-[40px] text-center leading-relaxed`}
        original-class="color-neutral-300 mg-bottom-40px"
      >
        {t("HeroDescription.text1")}
        <br className="hidden tablet:block laptop:block desktop:block" />
        {/* Add space on mobile */}
        <span className="mobile:inline tablet:hidden laptop:hidden desktop:hidden">
          &nbsp;
        </span>
        {t("HeroDescription.text2")}
      </p>
    </div>
  );
};

export default HeroDescription;
