import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SignInProvider } from "./contexts/SignInContext";
import { ToastProvider } from "./contexts/ToastContext";
import { PromptEmailProvider } from "./contexts/PromptEmailContext";
import { VideoProvider } from "./contexts/VideoContext";
import { RotatingLabelProvider } from "./contexts/RotatingLabelContext";
import { useState, useEffect, Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import Home from "./pages/Home";
import BlogPost from "./pages/BlogPost";
import TermsPage from "./pages/Legal/TermsPage";
import PrivacyPage from "./pages/Legal/PrivacyPage";
import EmailCaptureModal from "./components/EmailCaptureModal";
import ContactModal from "./components/ContactModal";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

// Scroll to top component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    // Set initial load to false after a small delay
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const handleEmailSubmit = (email: string) => {
    console.log("Email submitted:", email);
    setShowEmailModal(false);
  };

  if (isInitialLoad) {
    return null; // Return null during initial load to prevent flash
  }

  return (
    <I18nextProvider i18n={i18n}>
      <SignInProvider>
        <ThemeProvider>
          <ToastProvider>
            <PromptEmailProvider>
              <RotatingLabelProvider>
                <VideoProvider>
                  <Router>
                    <ScrollToTop />
                    <Suspense fallback={null}>
                      <div className="relative">
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <Home
                                setShowEmailModal={setShowEmailModal}
                                setShowContactModal={setShowContactModal}
                              />
                            }
                          />
                          <Route
                            path="/blog/introducing-createsite-ai"
                            element={<BlogPost />}
                          />
                          <Route path="/terms" element={<TermsPage />} />
                          <Route path="/privacy" element={<PrivacyPage />} />
                          <Route
                            path="/cookies"
                            element={<Navigate to="/" replace />}
                          />
                          <Route
                            path="/disclaimer"
                            element={<Navigate to="/" replace />}
                          />
                        </Routes>
                      </div>
                      {showEmailModal && (
                        <EmailCaptureModal
                          onSubmit={handleEmailSubmit}
                          onClose={() => setShowEmailModal(false)}
                        />
                      )}
                      {showContactModal && (
                        <ContactModal
                          isOpen={showContactModal}
                          onClose={() => setShowContactModal(false)}
                        />
                      )}
                    </Suspense>
                  </Router>
                </VideoProvider>
              </RotatingLabelProvider>
            </PromptEmailProvider>
          </ToastProvider>
        </ThemeProvider>
      </SignInProvider>
    </I18nextProvider>
  );
}

export default App;
