import React, { RefObject } from "react";
import { Canvas } from "@react-three/fiber";
import { useTheme } from "../../contexts/ThemeContext";
import ParticleSystem from "./ParticleSystem";
import useParticleOpacity from "./useParticleOpacity";

interface ParticleBackgroundProps {
  heroRef: RefObject<HTMLDivElement>;
}

const ParticleBackground: React.FC<ParticleBackgroundProps> = ({ heroRef }) => {
  const opacity = useParticleOpacity(heroRef);
  const { theme } = useTheme();

  return (
    <div className="absolute inset-0 z-0" style={{ opacity }}>
      <Canvas camera={{ position: [0, 0, 1] }}>
        <ParticleSystem
          opacity={opacity}
          color={theme === "light" ? "#000000" : "#ffffff"}
        />
      </Canvas>
    </div>
  );
};

export default ParticleBackground;
