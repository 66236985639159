import HeroContent from "./HeroContent";
import ParticleBackground from "./ParticleBackground";
import { useRef } from "react";
import { useTheme } from "../contexts/ThemeContext";

interface HeroSectionProps {
  setShowEmailModal: (show: boolean) => void;
}

function HeroSection({ setShowEmailModal }: HeroSectionProps) {
  const heroRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();

  return (
    <div
      ref={heroRef}
      className={`elem100 box-border z-[1] ${
        theme === "light" ? "bg-white" : "bg-black"
      } justify-center items-center flex relative overflow-hidden mobile:pt-[80px] pb-[64px] tablet:pt-[100px] laptop:pt-[120px] desktop:pt-[120px] min-h-screen relative`}
      original-class="section hero-section---home-page"
      style={{}}
    >
      <ParticleBackground heroRef={heroRef} />
      <div
        className={`absolute inset-0 bg-gradient-to-b ${
          theme === "light"
            ? "from-transparent to-white"
            : "from-transparent to-black"
        } opacity-30`}
      />
      <HeroContent setShowEmailModal={setShowEmailModal} />
    </div>
  );
}

export default HeroSection;
