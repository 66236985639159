import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";
import { FiExternalLink } from "react-icons/fi";

interface TestimonialCardProps {
  name: string;
  role: string;
  url?: string;
  text: string;
  image: string;
  secondaryImage?: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  name,
  role,
  text,
  image,
  url,
  secondaryImage,
}) => {
  const { theme } = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  // Split text at newline characters for conditional rendering
  const textParts = text.split("\n");

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.3 }}
      className={`p-12 rounded-2xl h-full ${
        theme === "light" ? "bg-gray-50 text-black" : "bg-gray-900 text-white"
      }`}
    >
      <div className="flex flex-col items-center text-center">
        <div
          className="relative mb-8 w-[240px] mx-auto"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="flex justify-center relative h-24 w-[240px] overflow-hidden">
            <motion.div
              className="w-24 h-24 rounded-full overflow-hidden border-4 border-white absolute pointer-events-none"
              animate={{
                x: isHovered ? -40 : 40,
                scale: isHovered ? 1.1 : 1,
                zIndex: isHovered ? 0 : 1,
              }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 20,
                zIndex: {
                  delay: isHovered ? 0 : 0.3,
                },
              }}
            >
              <img
                src={image}
                alt={name}
                className="w-full h-full object-cover"
              />
            </motion.div>
            {secondaryImage && (
              <motion.div
                className="w-24 h-24 rounded-full overflow-hidden border-4 border-white absolute pointer-events-none"
                initial={{ x: 40 }}
                animate={{
                  x: isHovered ? 40 : -40,
                  scale: isHovered ? 1.1 : 1,
                  zIndex: isHovered ? 1 : 0,
                }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  zIndex: {
                    delay: isHovered ? 0.3 : 0,
                  },
                }}
              >
                <img
                  src={secondaryImage}
                  alt={`${name} colleague`}
                  className="w-full h-full object-cover"
                />
              </motion.div>
            )}
          </div>
        </div>
        <p
          className={`text-xl mobile:text-lg tablet:text-xl laptop:text-xl desktop:text-xl leading-relaxed mobile:whitespace-normal tablet:whitespace-normal laptop:whitespace-pre-line desktop:whitespace-pre-line mb-8 ${
            theme === "light" ? "text-gray-700" : "text-gray-300"
          }`}
        >
          {textParts.map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index < textParts.length - 1 && (
                <>
                  <span className="mobile:hidden tablet:hidden laptop:inline desktop:inline">
                    {"\n"}
                  </span>
                  <span className="mobile:inline tablet:inline laptop:hidden desktop:hidden">
                    {" "}
                  </span>
                </>
              )}
            </React.Fragment>
          ))}
        </p>
        <div>
          <h3 className="font-bold text-xl mb-2">{name}</h3>
          <div className="flex items-center gap-2 justify-center">
            <p
              className={`${
                theme === "light" ? "text-gray-600" : "text-gray-400"
              }`}
            >
              {role}
            </p>
            {url && (
              <button
                onClick={() =>
                  window.open(url, "_blank", "noopener,noreferrer")
                }
                className={`p-1.5 rounded-full transition-colors duration-300 hover:opacity-70 ${
                  theme === "light" ? "text-gray-600" : "text-gray-400"
                }`}
                aria-label="Open website in new tab"
              >
                <FiExternalLink className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default TestimonialCard;
