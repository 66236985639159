export const announcementContent = {
  title: "Introducing CreateSite.ai 🎉",
  content: `We know you've heard this before - and been disappointed. Every "AI website builder" so far has been a frustrating mix of empty promises, restrictive limitations, and underwhelming results.

That's why we spent months building CreateSite.ai to actually work. Just write what you want, and AI builds your website. When you need changes, write again. That's it.

No rigid templates. No technical skills needed. No learning curve.

Welcome to the future of website creation.

―
Thomas & Lucas
Founders of CreateSite.ai`,
  alternativeContent: ``,
  separator: false,
  bottomContent: ``,
};
