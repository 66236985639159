import React, { createContext, useState, useContext, ReactNode } from "react";

interface SignInContextType {
  canOpenSignIn: boolean;
  openSignIn: () => void;
}

const SignInContext = createContext<SignInContextType | undefined>(undefined);

export const SignInProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [canOpenSignIn, setCanOpenSignIn] = useState(true);

  const openSignIn = () => {
    if (canOpenSignIn) {
      window.open("https://app.createsite.ai", "_blank", "noopener,noreferrer");
      setCanOpenSignIn(false);
      setTimeout(() => {
        setCanOpenSignIn(true);
      }, 2000);
    }
  };

  return (
    <SignInContext.Provider value={{ canOpenSignIn, openSignIn }}>
      {children}
    </SignInContext.Provider>
  );
};

export const useSignIn = () => {
  const context = useContext(SignInContext);
  if (context === undefined) {
    throw new Error("useSignIn must be used within a SignInProvider");
  }
  return context;
};
