import React, { ReactNode } from "react";

interface FullScreenSectionProps {
  children: ReactNode;
}

const FullScreenSection: React.FC<FullScreenSectionProps> = ({ children }) => {
  return (
    <section className="w-full h-screen mobile:h-[80vh] bg-black flex items-center justify-center">
      {children}
    </section>
  );
};

export default FullScreenSection;
