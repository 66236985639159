import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { announcementContent } from "../../constants/announcement";
import GetStarted from "../../components/CapabilitiesAndLimitations/GetStarted";
import { motion, AnimatePresence } from "framer-motion";
import i18n from "i18next";

const resources = {
  en: {
    "BlogPost.date": "November 4, 2024",
    "BlogPost.subtitle": "The simplest way to create a website.",
    "BlogPost.quote": "Create your website using AI",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const BlogPost: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Ensure all content is ready before showing
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isLoading && contentRef.current) {
      gsap.fromTo(
        contentRef.current,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: 0.8, ease: "power1.inOut" },
      );
    }
  }, [isLoading]);

  const handleGoBack = () => {
    if (contentRef.current) {
      gsap.to(contentRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => navigate("/"),
      });
    }
  };

  if (isLoading) {
    return (
      <div
        className={`fixed inset-0 ${
          theme === "light" ? "bg-white" : "bg-black"
        } z-50`}
      />
    );
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`fixed inset-0 ${
          theme === "light" ? "bg-white text-black" : "bg-black text-white"
        } overflow-y-auto`}
      >
        <div ref={contentRef} className="max-w-7xl mx-auto px-4 py-8">
          <div className="flex justify-between items-center mb-8 mobile:pl-4 tablet:pl-6">
            <button
              onClick={handleGoBack}
              className={`flex items-center justify-center p-2 border ${
                theme === "light" ? "border-black" : "border-white"
              } rounded-full ${
                theme === "light"
                  ? "text-black hover:bg-black hover:text-white"
                  : "text-white hover:bg-white hover:text-black"
              } bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#d2d3db] transition duration-300`}
            >
              <FiArrowLeft className="w-5 h-5" />
            </button>
          </div>
          <article
            className={`${
              theme === "light" ? "bg-white" : "bg-black"
            } rounded-lg overflow-hidden`}
          >
            <div className="p-8 pt-16">
              <p
                className={`text-lg mobile:text-lg tablet:text-xl laptop:text-xl desktop:text-xl ${
                  theme === "light" ? "text-[#525566]" : "text-[#f1f1f4]"
                } mb-4 text-center`}
              >
                {t("BlogPost.date")}
              </p>
              <h1
                className={`text-4xl mobile:text-4xl tablet:text-5xl laptop:text-6xl desktop:text-6xl font-bold mb-2 mobile:mb-4 ${
                  theme === "light" ? "text-black" : "text-white"
                } text-center mobile:whitespace-normal`}
              >
                {announcementContent.title}
              </h1>
              <p
                className={`text-xl mobile:text-xl tablet:text-2xl laptop:text-2xl desktop:text-3xl ${
                  theme === "light" ? "text-[#525566]" : "text-[#f1f1f4]"
                } mb-4 text-center mt-6 mobile:mt-4`}
              >
                {t("BlogPost.subtitle")}
              </p>

              <div
                className={`prose prose-quoteless ${
                  theme === "light" ? "prose-black" : "prose-invert"
                } max-w-3xl mx-auto`}
              >
                <div className="h-12"></div>
                <div className="relative text-center my-12">
                  <span
                    className={`text-2xl mobile:text-2xl tablet:text-3xl laptop:text-3xl desktop:text-3xl font-serif italic ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    <span className="text-gray-400">«</span>{" "}
                    {t("BlogPost.quote")}{" "}
                    <span className="text-gray-400">»</span>
                  </span>
                </div>
                <p
                  className={`text-xl whitespace-pre-wrap ${
                    theme === "light" ? "text-[#525566]" : "text-[#f1f1f4]"
                  } mb-8`}
                >
                  {announcementContent.content}
                </p>

                {announcementContent.separator && (
                  <hr
                    className={`my-24 border-t-2 ${
                      theme === "light" ? "border-gray-200" : "border-gray-800"
                    } w-1/3 mx-auto`}
                  />
                )}
              </div>
            </div>
          </article>
          <div className="mb-24">
            <GetStarted onClose={handleGoBack} />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default BlogPost;
