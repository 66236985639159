import React from "react";
import { useTranslation } from "react-i18next";

const SuccessMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <p className="text-xl font-semibold mb-4">
        {t("EmailCaptureModal.successTitle")}
      </p>
      <p className="mb-6">{t("EmailCaptureModal.successMessage")}</p>
    </div>
  );
};

export default SuccessMessage;
