import React, { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { usePromptEmail } from "../../contexts/PromptEmailContext";
import { useFormspark } from "@formspark/use-formspark";
import { useTranslation } from "react-i18next";
import SuccessMessage from "./SuccessMessage";

const EmailForm: React.FC = () => {
  const { email, setEmail, prompt } = usePromptEmail();
  const { theme } = useTheme();
  const [submit, submitting] = useFormspark({
    formId: "MQq85eyAd",
  });
  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "success" | "error"
  >("idle");
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      try {
        await submit({ email, prompt });
        setSubmitStatus("success");
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitStatus("error");
      }
    }
  };

  if (submitStatus === "success") {
    return <SuccessMessage />;
  }

  return (
    <>
      <p className="mb-6 text-lg">{t("EmailCaptureModal.description")}</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("EmailCaptureModal.placeholder")}
            className={`w-full px-4 py-3 rounded-md ${
              theme === "light"
                ? "bg-gray-100 text-gray-800 border border-gray-300"
                : "bg-[#0A0A0A] bg-opacity-50 text-white border border-white border-opacity-50"
            } focus:outline-none focus:ring-1 focus:ring-white focus:ring-opacity-50 transition-colors`}
            required
          />
        </div>
        <button
          type="submit"
          disabled={submitting}
          className={`w-full py-3 px-4 rounded-md ${
            theme === "light"
              ? "bg-black text-white hover:bg-opacity-90"
              : "bg-white text-black hover:bg-opacity-90"
          } transition-colors duration-300 font-medium`}
        >
          {submitting
            ? t("EmailCaptureModal.submitting")
            : t("EmailCaptureModal.submit")}
        </button>
      </form>
      {submitStatus === "error" && (
        <p className="text-red-500 mt-4 text-center">
          {t("EmailCaptureModal.errorMessage")}
        </p>
      )}
    </>
  );
};

export default EmailForm;
