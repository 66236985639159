import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FiCheck } from "react-icons/fi";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const resources = {
  en: {
    "PricingCard.getStarted": "Try it free for 14 days",
    "PricingCard.contactUs": "Contact Us",
    "PricingCard.getStartedFree": "Try it free for 14 days",
    "PricingCard.perWebsite": "per website",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

interface PricingCardProps {
  plan: {
    name: string;
    price: string;
    period: string;
    features: string[];
  };
  isPopular?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({ plan, isPopular }) => {
  const handleClick = () => {
    if (plan.name === "Business") {
      window.open(
        "https://forms.gle/bannfvR2jmyS6WvY8",
        "_blank",
        "noopener,noreferrer",
      );
    } else {
      if (plan.name === "Professional") {
        window.open(
          "https://app.createsite.ai/new/subscribe?plan=professional",
          "_blank",
          "noopener,noreferrer",
        );
      } else if (plan.name === "Starter") {
        window.open(
          "https://app.createsite.ai/new/subscribe?plan=starter",
          "_blank",
          "noopener,noreferrer",
        );
      } else {
        window.open(
          "https://app.createsite.ai",
          "_blank",
          "noopener,noreferrer",
        );
      }
    }
  };
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      className={`rounded-2xl p-8 flex flex-col justify-between h-full ${
        theme === "light"
          ? isPopular
            ? "bg-black text-white"
            : "bg-gray-50 text-black"
          : isPopular
            ? "bg-white text-black"
            : "bg-gray-900 text-white"
      } relative`}
    >
      {isPopular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
          <span
            className={`px-4 py-2 rounded-full text-sm font-medium border ${
              theme === "light"
                ? "bg-white text-black border-black/10"
                : "bg-black text-white border-white/10"
            }`}
          >
            Most Popular
          </span>
        </div>
      )}
      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
        <div className="flex flex-col">
          <div className="flex items-baseline">
            <span className="text-4xl font-bold">{plan.price}</span>
            <span className="ml-2 text-sm opacity-80">{plan.period}</span>
          </div>
          <span
            className={`text-sm ${theme === "light" ? "text-gray-500" : "text-gray-400"}`}
          >
            {t("PricingCard.perWebsite")}
          </span>
        </div>
      </div>
      <ul className="space-y-4 mb-8">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <FiCheck className="mr-3 flex-shrink-0" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <div className="mt-auto">
        <button
          onClick={handleClick}
          className={`w-full py-3 px-6 rounded-lg font-medium transition-all duration-300 ${
            theme === "light"
              ? isPopular
                ? "bg-white text-black hover:bg-opacity-80"
                : "bg-black text-white hover:bg-opacity-80"
              : isPopular
                ? "bg-black text-white hover:bg-opacity-80"
                : "bg-white text-black hover:bg-opacity-80"
          }`}
        >
          {plan.name === "Business"
            ? t("PricingCard.contactUs")
            : t("PricingCard.getStartedFree")}
        </button>
      </div>
    </motion.div>
  );
};

export default PricingCard;
