import { gsap } from "gsap";

export const buttonStyles = (isExpanded: boolean, theme: string) => `
  text-sm font-bold px-4 py-2
  ${isExpanded ? "" : "hover:bg-opacity-10"}
  ${theme === "light" ? "bg-white text-black" : "bg-black text-white"}
  rounded-full
  cursor-pointer flex justify-center items-center
`;

export const animateButton = (
  betaButtonRef: React.RefObject<HTMLDivElement>,
  theme: string,
) => {
  if (betaButtonRef.current) {
    gsap.to(betaButtonRef.current, {
      backgroundColor: "#ffffff",
      duration: 0.5,
      ease: "power2.out",
    });
  }
};

export const animateText = (
  betaTextRef: React.RefObject<HTMLSpanElement>,
  theme: string,
  setTextColor: (color: string) => void,
) => {
  gsap.to(betaTextRef.current, {
    color: theme === "light" ? "#000000" : "#000000",
    duration: 0.3,
    ease: "power2.out",
    onUpdate: () => {
      if (betaTextRef.current) {
        setTextColor(getComputedStyle(betaTextRef.current).color);
      }
    },
  });
};

export const createConfetti = (
  container: HTMLDivElement,
  x: number,
  y: number,
  isBurst: boolean = false,
) => {
  const confettiCount = isBurst ? 50 : 1;
  const confettiElements: HTMLDivElement[] = [];

  for (let i = 0; i < confettiCount; i++) {
    const confetti = document.createElement("div");
    confetti.className = "absolute w-2 h-1";
    confetti.style.left = `${x}px`;
    confetti.style.top = `${y}px`;
    confetti.style.backgroundColor = getRandomColor();
    container.appendChild(confetti);
    confettiElements.push(confetti);
  }

  gsap.to(confettiElements, {
    x: () => gsap.utils.random(-300, 300),
    y: () => gsap.utils.random(-300, 300),
    rotation: gsap.utils.random(-180, 180),
    opacity: 0,
    duration: isBurst ? 4 : 2,
    ease: "power2.out",
    stagger: isBurst ? 0.02 : 0,
    onComplete: () => {
      confettiElements.forEach((el) => {
        if (container.contains(el)) {
          container.removeChild(el);
        }
      });
    },
  });
};

export const getRandomColor = () => {
  const colors = [
    "#3099ff",
    "#f1f1f4",
    "#050719",
    "#525566",
    "#FF69B4",
    "#39FF14",
    "#00FFFF",
    "#FF1493",
    "#7CFC00",
    "#1E90FF",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};
