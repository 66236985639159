import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
  useCallback,
  useRef,
} from "react";

const websiteIdeas = [
  "I want a website for a cybersecurity startup developing AI-powered pentesting",
  "I want a website for a cleantech company creating advanced carbon capture technology",
  "I want a website for a deep tech startup developing quantum computing solutions",
  "I want a website for a robotics company building autonomous systems",
  "I want a website for a software startup building real-time collaboration tools",
];

interface RotatingLabelContextType {
  currentLabel: string;
  resetTimer: () => void;
  setNextLabel: () => void;
  currentLabel: string;
  resetTimer: () => void;
}

const RotatingLabelContext = createContext<
  RotatingLabelContextType | undefined
>(undefined);

export const RotatingLabelProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();

  const setNextLabel = useCallback(() => {
    setCurrentIndex((prev) => (prev + 1) % websiteIdeas.length);
  }, []);
  const startInterval = useCallback(() => {
    return setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % websiteIdeas.length);
    }, 5000);
  }, []);

  const resetTimer = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = startInterval();
  }, [startInterval]);

  useEffect(() => {
    intervalRef.current = startInterval();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [startInterval]);

  return (
    <RotatingLabelContext.Provider
      value={{
        currentLabel: websiteIdeas[currentIndex],
        resetTimer,
        setNextLabel,
      }}
    >
      {children}
    </RotatingLabelContext.Provider>
  );
};

export const useRotatingLabel = () => {
  const context = useContext(RotatingLabelContext);
  if (!context) {
    throw new Error(
      "useRotatingLabel must be used within a RotatingLabelProvider",
    );
  }
  return context;
};
