import React, { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";

interface ContactFormProps {
  onSubmit: (email: string, message: string) => void;
  submitting: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ onSubmit, submitting }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { theme } = useTheme();
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email, message);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label
          htmlFor="email"
          className={`block mb-2 ${theme === "light" ? "text-gray-700" : "text-gray-300"} font-medium`}
        >
          {t("ContactModal.emailLabel")}
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`w-full px-4 py-3 rounded-md ${
            theme === "light"
              ? "bg-gray-100 text-gray-800 border border-gray-300"
              : "bg-[#0A0A0A] bg-opacity-50 text-white border border-white border-opacity-50"
          } focus:outline-none focus:ring-1 focus:ring-white focus:ring-opacity-50 transition-colors`}
          required
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="message"
          className={`block mb-2 ${theme === "light" ? "text-gray-700" : "text-gray-300"} font-medium`}
        >
          {t("ContactModal.messageLabel")}
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className={`w-full px-4 py-3 rounded-md ${
            theme === "light"
              ? "bg-gray-100 text-gray-800 border border-gray-300"
              : "bg-[#0A0A0A] bg-opacity-50 text-white border border-white border-opacity-50"
          } focus:outline-none focus:ring-1 focus:ring-white focus:ring-opacity-50 transition-colors h-32 resize-none`}
          required
        ></textarea>
      </div>
      <button
        type="submit"
        disabled={submitting}
        className={`w-full py-3 px-4 rounded-md ${
          theme === "light"
            ? "bg-black text-white hover:bg-opacity-90"
            : "bg-white text-black hover:bg-opacity-90"
        } transition-colors duration-300 font-medium`}
      >
        {submitting
          ? t("ContactModal.submitting")
          : t("ContactModal.submitButton")}
      </button>
    </form>
  );
};

export default ContactForm;
