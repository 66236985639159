import { useState, useEffect } from "react";

export const useRotatingWord = (words: string[], interval: number = 5000) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const transitionInterval = setInterval(() => {
      setIsTransitioning(true);

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsTransitioning(false);
      }, 800);
    }, interval);

    return () => clearInterval(transitionInterval);
  }, [words, interval]);

  return {
    currentWord: words[currentIndex],
    isTransitioning,
  };
};
