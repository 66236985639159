import React from "react";
import { ContactModalProps } from "./types";
import useContactModal from "./useContactModal";
import ContactModalContent from "./ContactModalContent";

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
  const { modalRef, modalContentRef, handleOutsideClick } = useContactModal(
    isOpen,
    onClose,
  );

  if (!isOpen) return null;

  return (
    <div
      ref={modalRef}
      className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"
      style={{
        boxShadow: "0 0 0 100vmax rgba(0, 0, 0, 0.5)",
      }}
      onClick={handleOutsideClick}
    >
      <ContactModalContent
        modalContentRef={modalContentRef}
        onClose={onClose}
      />
    </div>
  );
};

export default ContactModal;
