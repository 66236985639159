import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiCheckCircle, FiXCircle, FiX } from "react-icons/fi";
import { useTheme } from "../ThemeContext";
import { Toast } from "./types";

interface ToastContainerProps {
  toasts: Toast[];
  modalRef: React.RefObject<HTMLDivElement>;
  setToasts: React.Dispatch<React.SetStateAction<Toast[]>>;
}

const ToastContainer: React.FC<ToastContainerProps> = ({
  toasts,
  modalRef,
  setToasts,
}) => {
  const { theme } = useTheme();

  return (
    <AnimatePresence>
      {toasts.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <motion.div
            ref={modalRef}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className={`${
              theme === "light"
                ? "bg-white text-black"
                : "bg-gray-800 text-white"
            } rounded-lg shadow-lg p-6 max-w-md w-full mx-4`}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Notification</h2>
              <button
                onClick={() => setToasts([])}
                className={`${
                  theme === "light" ? "text-black" : "text-white"
                } hover:opacity-70 transition-colors duration-300`}
              >
                <FiX size={24} />
              </button>
            </div>
            {toasts.map((toast) => (
              <div
                key={toast.id}
                className={`${
                  theme === "light" ? "bg-gray-100" : "bg-gray-700"
                } rounded-lg p-4 mb-2 flex items-center`}
              >
                {toast.type === "success" ? (
                  <FiCheckCircle className="text-green-500 mr-2" size={20} />
                ) : (
                  <FiXCircle className="text-red-500 mr-2" size={20} />
                )}
                <span>{toast.message}</span>
              </div>
            ))}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ToastContainer;
