import { useRef, useState } from "react";
import { gsap } from "gsap";
import { useTheme } from "../../contexts/ThemeContext";
import { createConfetti } from "./styles";

export const useFloatingBetaButton = (onExpand: () => void) => {
  const betaButtonRef = useRef<HTMLDivElement>(null);
  const betaTextRef = useRef<HTMLSpanElement>(null);
  const confettiRef = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [textOpacity, setTextOpacity] = useState(1);
  const [isHovering, setIsHovering] = useState(false);
  const [confettiInterval, setConfettiInterval] =
    useState<NodeJS.Timeout | null>(null);

  const { theme } = useTheme();

  const handleBetaClick = (onComplete: () => void) => {
    if (isExpanded || !betaButtonRef.current) return;
    setIsExpanded(true);
    onExpand();

    const buttonRect = betaButtonRef.current.getBoundingClientRect();
    const centerY = window.innerHeight / 2;
    const moveY = centerY - (buttonRect.top + buttonRect.height / 2);

    const scaleX = window.innerWidth / buttonRect.width;
    const scaleY = window.innerHeight / buttonRect.height;
    const scale = Math.max(scaleX, scaleY);

    tl.current = gsap.timeline({
      defaults: { ease: "power3.inOut" },
      onComplete: () => {
        setTimeout(onComplete, 250);
      },
    });

    tl.current
      .to(betaTextRef.current, {
        opacity: 0,
        duration: 0.2,
        ease: "power2.out",
        onComplete: () => setTextOpacity(0),
      })
      .to(betaButtonRef.current, {
        paddingTop: "40px",
        duration: 0.2,
      })
      .to(
        betaButtonRef.current,
        {
          duration: 0.6,
          scale: scale,
          y: moveY,
          xPercent: -50,
          left: "50%",
          transformOrigin: "center center",
        },
        "-=0.1",
      );
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsHovering(true);
    if (!isExpanded && confettiRef.current) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      createConfetti(confettiRef.current, x, y, true);

      const interval = setInterval(() => {
        if (confettiRef.current) {
          const x = Math.random() * confettiRef.current.clientWidth;
          const y = Math.random() * confettiRef.current.clientHeight;
          createConfetti(confettiRef.current, x, y);
        }
      }, 50);

      setConfettiInterval(interval);
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (confettiInterval) {
      clearInterval(confettiInterval);
      setConfettiInterval(null);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isHovering && !isExpanded && confettiRef.current) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      createConfetti(confettiRef.current, x, y);
    }
  };

  return {
    betaButtonRef,
    betaTextRef,
    confettiRef,
    isExpanded,
    textOpacity,
    theme,
    handleBetaClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  };
};

export default useFloatingBetaButton;
