import { useDataWIdAnimation24 } from "./animations/useDataWIdAnimation24";
import { useDataWIdAnimation25 } from "./animations/useDataWIdAnimation25";
import { useDataWIdAnimation26 } from "./animations/useDataWIdAnimation26";
import { useDataWIdAnimation30 } from "./animations/useDataWIdAnimation30";
import { useDataWIdAnimation31 } from "./animations/useDataWIdAnimation31";
import { useDataWIdAnimation32 } from "./animations/useDataWIdAnimation32";
import { useDataWIdAnimation33 } from "./animations/useDataWIdAnimation33";
import { useDataWIdAnimation34 } from "./animations/useDataWIdAnimation34";

export function useAnimations() {
  useDataWIdAnimation24();
  useDataWIdAnimation25();
  useDataWIdAnimation26();
  useDataWIdAnimation30();
  useDataWIdAnimation31();
  useDataWIdAnimation32();
  useDataWIdAnimation33();
  useDataWIdAnimation34();
}