import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useFormspark } from "@formspark/use-formspark";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { ContactModalContentProps, SubmitStatus } from "./types";
import ContactForm from "./ContactForm";

const ContactModalContent: React.FC<ContactModalContentProps> = ({
  modalContentRef,
  onClose,
}) => {
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>("idle");
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [submit, submitting] = useFormspark({
    formId: "PkD9tIjh9",
  });

  const handleSubmit = async (email: string, message: string) => {
    if (email && message) {
      try {
        await submit({ email, message });
        setSubmitStatus("success");
      } catch (error) {
        setSubmitStatus("error");
      }
    }
  };

  return (
    <div
      ref={modalContentRef}
      className={`${
        theme === "light" ? "bg-white text-black" : "bg-black text-white"
      } p-8 rounded-lg shadow-xl max-w-md w-full`}
    >
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">{t("ContactModal.title")}</h2>
        <button
          onClick={onClose}
          className={`${
            theme === "light" ? "text-black" : "text-white"
          } hover:opacity-70 transition-colors duration-300`}
        >
          <FaTimes />
        </button>
      </div>
      {submitStatus === "success" ? (
        <div className="text-center">
          <p className="text-xl font-semibold mb-4">
            {t("ContactModal.successTitle")}
          </p>
          <p className="mb-6">{t("ContactModal.successMessage")}</p>
        </div>
      ) : (
        <ContactForm onSubmit={handleSubmit} submitting={submitting} />
      )}
      {submitStatus === "error" && (
        <p className="text-red-500 mt-4 text-center">
          {t("ContactModal.errorMessage")}
        </p>
      )}
    </div>
  );
};

export default ContactModalContent;
