import { gsap } from "gsap";
import { useEffect } from "react";

export function useDataWIdAnimation24() {
  useEffect(() => {
    gsap.set('[data-w-id="fa3d264c-0fef-9855-1fa8-f34b79da40d4"]', {"y": "10%"});
    gsap.set('[data-w-id="fa3d264c-0fef-9855-1fa8-f34b79da40d4"]', {"opacity": 0});
                const tl = gsap.timeline({"scrollTrigger": {"trigger": "[data-w-id=\"fa3d264c-0fef-9855-1fa8-f34b79da40d4\"]", "start": "top 70%", "end": "bottom top", "toggleActions": "play none none none", "once": true}});
      tl.addLabel("label0");
      tl.to('[data-w-id="fa3d264c-0fef-9855-1fa8-f34b79da40d4"]', {"duration": 0.5, "delay": 0.2, "ease": "power1.inOut", "y": "0%"}, "label0");
      tl.to('[data-w-id="fa3d264c-0fef-9855-1fa8-f34b79da40d4"]', {"duration": 0.5, "delay": 0.2, "ease": "power1.inOut", "opacity": 1}, "label0");
    return () => {
      tl.kill();
    };
  }, []);
}