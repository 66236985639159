import React, { useRef, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { gsap } from "gsap";

interface ModalWrapperProps {
  children: React.ReactNode;
  onClose: () => void;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({ children, onClose }) => {
  const { theme } = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current && modalContentRef.current) {
      gsap.set(modalRef.current, { opacity: 0 });
      gsap.set(modalContentRef.current, { scale: 0.9, opacity: 0 });

      gsap.to(modalRef.current, {
        opacity: 1,
        duration: 0.3,
        ease: "power2.inOut",
      });

      gsap.to(modalContentRef.current, {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        ease: "back.out(1.7)",
      });
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClose = () => {
    if (modalRef.current && modalContentRef.current) {
      gsap.to(modalRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.inOut",
      });

      gsap.to(modalContentRef.current, {
        scale: 0.9,
        opacity: 0,
        duration: 0.3,
        ease: "power2.in",
        onComplete: onClose,
      });
    }
  };

  return (
    <div
      ref={modalRef}
      className={`fixed inset-0 z-[9999] flex items-center justify-center ${
        theme === "light" ? "bg-black bg-opacity-50" : "bg-black bg-opacity-50"
      } backdrop-filter backdrop-blur-sm`}
      style={{
        boxShadow: "0 0 0 100vmax rgba(0, 0, 0, 0.5)",
      }}
      onClick={(event) => {
        if (
          modalContentRef.current &&
          !modalContentRef.current.contains(event.target as Node)
        ) {
          handleClose();
        }
      }}
    >
      <div ref={modalContentRef}>{children}</div>
    </div>
  );
};

export default ModalWrapper;
