export const scrollToSection = (
  elementId: string,
  isMobile: boolean = false,
  onComplete?: () => void,
) => {
  const element = document.getElementById(elementId);
  if (element) {
    if (isMobile) {
      // For mobile menu, we need to handle the menu closing first
      // Remove fixed positioning and restore scroll position
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      const previousScroll = parseInt(scrollY || "0") * -1;
      window.scrollTo(0, previousScroll);

      // Wait for the menu closing animation
      setTimeout(() => {
        const offset = 60; // Consistent offset for mobile
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - offset,
          behavior: "smooth",
        });
        if (onComplete) onComplete();
      }, 100);
    } else {
      // For desktop/footer navigation
      const navbarHeight = 80; // Height of the sticky navbar
      const isMobileDevice = window.innerWidth < 768;
      const offset = isMobileDevice ? 60 : navbarHeight;

      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
      if (onComplete) onComplete();
    }
  }
};
