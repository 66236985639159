import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import TestimonialCard from "./TestimonialCard";
import i18n from "i18next";

const resources = {
  en: {
    "Testimonials.title": "Your creativity is the limit",
    "Testimonials.testimonials": [
      {
        name: "Augustin Perraud & Peter Starr",
        role: "Founders of Freeda",
        url: "https://31232aa2-2b9c-43e7-a514-fbd48e4281f5.vercel.app/",
        text: "CreateSite.ai just saved us months of work. We got a gorgeous, professional-grade site in literally minutes. The AI's ability to bring our ideas to life is a magical experience. You need to try it.",
        image:
          "https://createsiteai.b-cdn.net/c/_/4278f109-3a72-4702-a732-d4018c9e84a3",
        secondaryImage:
          "https://createsiteai.b-cdn.net/c/_/bf268a10-d448-4c44-8306-6556592af3ed",
      },
      {
        name: "Hugo Le Belzic & Guillaume Bigot",
        role: "Founders of Pyrrha",
        url: "https://pyrrha.fr",
        text: "This is what the future feels like. We were disappointed by every AI website builder we tried – until CreateSite.ai. The quality gap is so massive, you won't believe what it can do.",
        image:
          "https://createsiteai.b-cdn.net/c/_/101872c4-b2f8-4dfe-a8d9-cdb42b6400ee",
        secondaryImage:
          "https://createsiteai.b-cdn.net/c/_/e8d3486d-28fe-476b-ae3c-5d30b8e92bd0",
      },
    ],
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const Testimonials: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const testimonials = t("Testimonials.testimonials", {
    returnObjects: true,
  }) as Array<{
    name: string;
    role: string;
    text: string;
    image: string;
  }>;

  return (
    <div className={`py-40 ${theme === "light" ? "bg-white" : "bg-black"}`}>
      <div className="max-w-7xl mx-auto px-4">
        <h2
          className={`text-4xl md:text-6xl lg:text-7xl mobile:text-3xl tablet:text-6xl laptop:text-7xl desktop:text-7xl font-bold text-center ${
            theme === "light" ? "text-black" : "text-white"
          } mb-20`}
        >
          {t("Testimonials.title")}
        </h2>
        <div className="grid mobile:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-2 gap-8">
          {testimonials.slice(0, 2).map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
