import { useState, useEffect, RefObject } from "react";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const useParticleOpacity = (heroRef: RefObject<HTMLDivElement>) => {
  const scrollPosition = useScrollPosition();
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const heroHeight = heroRef.current
      ? heroRef.current.offsetHeight
      : window.innerHeight; // Assuming hero section is full viewport height
    const fadeOutStart = heroHeight * 0.6;
    const fadeOutEnd = heroHeight;

    if (scrollPosition <= fadeOutStart) {
      setOpacity(1);
    } else if (scrollPosition >= fadeOutEnd) {
      setOpacity(0);
    } else {
      const fadeProgress =
        (scrollPosition - fadeOutStart) / (fadeOutEnd - fadeOutStart);
      setOpacity(1 - fadeProgress);
    }
  }, [scrollPosition, heroRef]);

  return opacity;
};

export default useParticleOpacity;
