import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { usePromptEmail } from "../../contexts/PromptEmailContext";
import { useTranslation } from "react-i18next";
import { useFormspark } from "@formspark/use-formspark";
import { motion, AnimatePresence } from "framer-motion";
import SuccessMessage from "./SuccessMessage";
import WaveButton from "./WaveButton";
import LoadingSkeleton from "./LoadingSkeleton";
import BrowserWindow from "../BrowserWindow/BrowserWindow";
import { useRotatingLabel } from "../../contexts/RotatingLabelContext";
import i18n from "i18next";

const resources = {
  en: {
    "UnifiedPromptInput.submit": "Generate my website",
    "UnifiedPromptInput.submitting": "Submitting...",
    "UnifiedPromptInput.placeholder": "Describe your website idea...",
    "UnifiedPromptInput.customerWebsites": "Made with CreateSite.ai",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const websiteIdeas = [
  {
    text: "I want a website for a cybersecurity startup developing frontier AI models for offensive security testing",
    image:
      "https://createsiteai.b-cdn.net/c/_/73c6717b-7a9e-4513-9202-b7a4f3d5e481",
    url: "pyrrha",
  },
  {
    text: "I want a website for a startup developing digital asset management systems for the construction industry",
    image:
      "https://createsiteai.b-cdn.net/c/_/ed83108d-e910-435f-a01a-654c11432d8b",
    url: "freeda",
  },
];

interface UnifiedPromptInputProps {
  setShowEmailModal: (show: boolean) => void;
}

function UnifiedPromptInput({ setShowEmailModal }: UnifiedPromptInputProps) {
  const { prompt, setPrompt, autoFocus, setAutoFocus } = usePromptEmail();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submit, submitting] = useFormspark({
    formId: "MQq85eyAd",
  });
  const [currentIdeaIndex, setCurrentIdeaIndex] = useState(0);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);
  const [contentOpacity, setContentOpacity] = useState(1);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(
    websiteIdeas[0].text,
  );
  const [lastLabel, setLastLabel] = useState(websiteIdeas[0].text);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const { currentLabel, resetTimer, setNextLabel } = useRotatingLabel();

  // Calculate fill percentage based on prompt length
  const maxLength = 50; // Maximum length for 100% fill
  const fillPercentage = Math.min((prompt.length / maxLength) * 100, 100);

  useEffect(() => {
    if (autoFocus && textareaRef.current) {
      textareaRef.current.focus();
      setAutoFocus(false); // Reset after focusing
    }
  }, [autoFocus, setAutoFocus]);

  useEffect(() => {
    const interval = setInterval(() => {
      setContentOpacity(0);
      setTimeout(() => {
        setCurrentIdeaIndex((prev) => {
          const nextIndex = (prev + 1) % websiteIdeas.length;
          const newText = websiteIdeas[nextIndex].text;
          setCurrentPlaceholder(newText);
          setLastLabel(newText);
          return nextIndex;
        });
        setContentOpacity(1);
      }, 800);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    window.open(
      `https://app.createsite.ai/new/subscribe?prompt=${encodeURIComponent(prompt)}`,
      "_blank",
      "noopener,noreferrer",
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const wasEmpty = prompt.length === 0;
    setPrompt(newValue);
    const newOpacity = newValue.length > 0 ? 0 : 1;
    setContentOpacity(newOpacity);

    if (wasEmpty && newValue.length > 0) {
      resetTimer();
    }

    if (newValue === "") {
      setCurrentPlaceholder(lastLabel);
    }
  };

  return (
    <div className="w-full flex flex-col items-center relative h-auto">
      <AnimatePresence mode="wait">
        {!isSubmitted ? (
          <motion.form
            className="w-full max-w-3xl flex flex-col items-center"
            key="form"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onSubmit={handleSubmit}
          >
            <div className="w-full mb-0 relative">
              <textarea
                ref={textareaRef}
                value={prompt}
                onChange={handleInputChange}
                placeholder={currentPlaceholder}
                style={
                  {
                    "--placeholder-opacity":
                      prompt.length > 0 ? 0 : contentOpacity,
                    transition: "opacity 0.3s ease-in-out",
                  } as React.CSSProperties
                }
                className={`w-full px-8 py-6 rounded-xl border-2 hero-description-text ${
                  theme === "light"
                    ? "border-black/10 text-black placeholder-gray-500 focus:border-black/20"
                    : "border-white/10 text-white placeholder-gray-400 focus:border-white/20"
                } bg-white focus:outline-none focus:ring-0 transition-colors duration-300 mobile:min-h-[160px] tablet:min-h-[150px] laptop:min-h-[150px] desktop:min-h-[150px] resize-none shadow-sm`}
                required={false}
              />
              <AnimatePresence mode="wait">
                <motion.label
                  ref={labelRef}
                  key={currentPlaceholder}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: prompt.length > 0 ? 1 : 0 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  exit={{ opacity: 0 }}
                  className={`absolute left-1/2 transform -translate-x-1/2 -mt-[11px] px-4 backdrop-blur-sm text-center whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] h-[22px] leading-[22px] ${
                    theme === "light"
                      ? "text-gray-600 bg-white/80"
                      : "text-gray-400 bg-black/80"
                  } text-sm rounded-full`}
                >
                  {currentPlaceholder}
                </motion.label>
              </AnimatePresence>
            </div>

            <div className="w-full flex flex-col-reverse gap-4">
              <p
                className={`text-center transition-all duration-300 ${
                  theme === "light" ? "text-gray-600" : "text-gray-400"
                } mobile:text-sm tablet:text-base laptop:text-base desktop:text-base not-italic`}
                style={{
                  opacity: prompt.length > 0 ? 0 : 1,
                  pointerEvents: prompt.length > 0 ? "none" : "auto",
                }}
              >
                {t("UnifiedPromptInput.customerWebsites")}
              </p>
              <div className="aspect-[16/9] relative">
                <div
                  className="w-full transition-all duration-300"
                  style={{
                    opacity: prompt.length > 0 ? 0 : 1,
                    pointerEvents: prompt.length > 0 ? "none" : "auto",
                  }}
                >
                  <BrowserWindow
                    url={websiteIdeas[currentIdeaIndex].url}
                    opacity={contentOpacity}
                  >
                    <div className="relative mobile:h-[240px] tablet:h-[440px] laptop:h-[440px] desktop:h-[440px] overflow-hidden">
                      <LoadingSkeleton />
                      <motion.img
                        key={currentIdeaIndex}
                        src={websiteIdeas[currentIdeaIndex].image}
                        alt="Customer website preview"
                        className="absolute inset-0 w-full h-full object-cover object-top"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: contentOpacity }}
                        transition={{ duration: 0.8 }}
                      />
                    </div>
                  </BrowserWindow>
                </div>
                <div
                  className="absolute top-0 left-0 w-full pt-4 flex items-center justify-center transition-all duration-300"
                  style={{
                    opacity: prompt.length > 0 ? 1 : 0,
                    pointerEvents: prompt.length > 0 ? "auto" : "none",
                  }}
                >
                  <WaveButton
                    type="submit"
                    disabled={submitting}
                    theme={theme}
                    submitting={submitting}
                    fillPercentage={fillPercentage}
                  >
                    {submitting
                      ? t("UnifiedPromptInput.submitting")
                      : t("UnifiedPromptInput.submit")}
                  </WaveButton>
                </div>
              </div>
            </div>
          </motion.form>
        ) : (
          <motion.div
            key="success"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <SuccessMessage />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default UnifiedPromptInput;
