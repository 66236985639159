import React, { createContext, useState, useContext, ReactNode } from "react";

interface PromptEmailContextType {
  prompt: string;
  setPrompt: (prompt: string) => void;
  email: string;
  setEmail: (email: string) => void;
  autoFocus: boolean;
  setAutoFocus: (autoFocus: boolean) => void;
}

const PromptEmailContext = createContext<PromptEmailContextType | undefined>(
  undefined,
);

export const PromptEmailProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [prompt, setPrompt] = useState("");
  const [email, setEmail] = useState("");
  const [autoFocus, setAutoFocus] = useState(false);

  return (
    <PromptEmailContext.Provider
      value={{ prompt, setPrompt, email, setEmail, autoFocus, setAutoFocus }}
    >
      {children}
    </PromptEmailContext.Provider>
  );
};

export const usePromptEmail = () => {
  const context = useContext(PromptEmailContext);
  if (context === undefined) {
    throw new Error("usePromptEmail must be used within a PromptEmailProvider");
  }
  return context;
};
