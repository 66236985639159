import React, { useRef } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { usePromptEmail } from "../../contexts/PromptEmailContext";
import i18n from "i18next";

const resources = {
  en: {
    "GetStarted.title": "Launch your dream website today",
    "GetStarted.button": "Create my website ✨",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

interface GetStartedProps {
  onClose: () => void;
}

const GetStarted: React.FC<GetStartedProps> = ({ onClose }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAutoFocus } = usePromptEmail();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    window.open("https://app.createsite.ai", "_blank", "noopener,noreferrer");
    if (onClose) {
      onClose();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`${
        theme === "light" ? "bg-gray-50" : "bg-gray-900"
      } rounded-2xl p-8 max-w-3xl mx-auto text-center space-y-8`}
    >
      <h3
        className={`text-3xl font-bold ${
          theme === "light" ? "text-black" : "text-white"
        }`}
      >
        {t("GetStarted.title")}
      </h3>
      <div className="relative inline-block">
        <button
          ref={buttonRef}
          onClick={handleClick}
          className={`relative px-8 py-4 text-xl font-bold rounded-full transition-all duration-300 transform hover:scale-105 ${
            theme === "light" ? "bg-black text-white" : "bg-white text-black"
          }`}
        >
          {t("GetStarted.button")}
        </button>
      </div>
    </motion.div>
  );
};

export default GetStarted;
