import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import PricingCard from "./PricingCard";
import i18n from "i18next";

const resources = {
  en: {
    "PricingSection.title": "Pricing",
    "PricingSection.plans": {
      starter: {
        name: "Starter",
        price: "$17",
        period: "/month",
        features: ["1 page", "Unlimited AI edits", "Custom domain"],
      },
      pro: {
        name: "Professional",
        price: "$29",
        period: "/month",
        features: [
          "5 pages",
          "Unlimited AI edits",
          "Custom domain",
          "Priority support",
        ],
      },
      business: {
        name: "Business",
        price: "$99",
        period: "/month",
        features: [
          "Unlimited pages",
          "Unlimited AI edits",
          "Custom domain",
          "Priority support",
          "Advanced analytics",
        ],
      },
    },
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const PricingSection: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div
      id="pricing-section"
      className={`py-20 ${theme === "light" ? "bg-white" : "bg-black"}`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-20">
          <h2
            className={`text-4xl md:text-6xl lg:text-7xl mobile:text-3xl tablet:text-6xl laptop:text-7xl desktop:text-7xl font-bold ${
              theme === "light" ? "text-black" : "text-white"
            } mb-6`}
          >
            {t("PricingSection.title")}
          </h2>
        </div>
        <div className="grid mobile:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-3 desktop:grid-cols-3 gap-8">
          {Object.keys(t("PricingSection.plans", { returnObjects: true })).map(
            (plan) => (
              <PricingCard
                key={plan}
                plan={t(`PricingSection.plans.${plan}`, {
                  returnObjects: true,
                })}
                isPopular={plan === "pro"}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
