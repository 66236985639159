import React from "react";
import { motion } from "framer-motion";
import { useFloatingBetaButton } from "./useFloatingBetaButton";
import ConfettiEffect from "./ConfettiEffect";
import { buttonStyles } from "./styles";
import { useNavigate } from "react-router-dom";

interface FloatingBetaButtonProps {
  onExpand: () => void;
  isVisible: boolean;
}

const FloatingBetaButton: React.FC<FloatingBetaButtonProps> = ({
  onExpand,
  isVisible,
}) => {
  const {
    betaButtonRef,
    betaTextRef,
    confettiRef,
    isExpanded,
    textOpacity,
    theme,
    handleBetaClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  } = useFloatingBetaButton(onExpand);

  const navigate = useNavigate();

  const handleClick = () => {
    handleBetaClick(() => {
      setTimeout(() => {
        navigate("/blog/introducing-createsite-ai");
      }, 800);
    });
  };

  if (!isVisible) return null;

  return (
    <motion.div
      ref={betaButtonRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "power2.out" }}
      className={`${buttonStyles(isExpanded, theme)} fixed transform -translate-x-1/2 left-1/2 z-[9999] mobile:hidden tablet:hidden laptop:block desktop:block laptop:top-8 desktop:top-8 w-auto min-w-[230px] transition-colors duration-500`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme === "light" ? "#000000" : "#ffffff",
        backgroundColor: isExpanded ? "#ffffff" : "transparent",
        position: "fixed",
      }}
    >
      <span
        ref={betaTextRef}
        style={{
          opacity: textOpacity,
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
        className="w-full flex justify-center items-center beta-button-text"
      >
        Announcing our public beta 🎉
      </span>
      <ConfettiEffect ref={confettiRef} />
    </motion.div>
  );
};

export default FloatingBetaButton;
