import React, { useRef } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import useGrowingImageEffects from "./useGrowingImageEffects";
import useConfettiEffect from "./useConfettiEffect";
import i18n from "i18next";

const resources = {
  en: {
    "GrowingImage.title": "It's your turn now",
    "GrowingImage.button": "Create my website 🥳",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const GrowingImage: React.FC = () => {
  const { theme } = useTheme();
  const imageRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const confettiRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { handleMouseEnter, handleMouseLeave, handleMouseMove } =
    useGrowingImageEffects(buttonRef, confettiRef);
  useConfettiEffect(confettiRef);

  const handleClick = () => {
    window.open("https://app.createsite.ai", "_blank", "noopener,noreferrer");
  };

  return (
    <div
      ref={imageRef}
      className={`relative w-full h-full overflow-hidden flex items-center justify-center px-4 mobile:px-2 tablet:px-3 ${
        theme === "light" ? "bg-white" : "bg-black"
      }`}
    >
      <div
        className={`flex flex-col items-center justify-center w-full h-full mobile:w-[90%] tablet:w-[90%] ${
          theme === "light" ? "bg-white" : "bg-black"
        }`}
      >
        <h2
          className={`text-4xl md:text-6xl lg:text-7xl mobile:text-3xl tablet:text-6xl laptop:text-7xl desktop:text-8xl mobile:text-3xl font-bold ${
            theme === "light" ? "text-black" : "text-white"
          } text-center mb-6`}
        >
          {t("GrowingImage.title")}
        </h2>
        <div className="relative">
          <button
            ref={buttonRef}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            className={`mt-3 mobile:mt-1 mobile:text-sm transition-colors duration-300 font-medium py-2 px-4 rounded-md border ${
              theme === "light"
                ? "bg-black text-white hover:bg-white hover:text-black border-black"
                : "bg-white text-[#030410] hover:bg-transparent hover:text-white border-white"
            }`}
          >
            {t("GrowingImage.button")}
          </button>
          <div
            ref={confettiRef}
            className="absolute inset-0 pointer-events-none"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default GrowingImage;
