import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { gsap } from "gsap";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import i18n from "i18next";

const resources = {
  en: {
    "FloatingAnnouncement.title": "Announcing our public beta",
    "FloatingAnnouncement.expanded": "Create your website using AI",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const FloatingAnnouncement: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { theme } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const scrollPosition = useScrollPosition();
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    // Get hero section height
    const heroSection = document.querySelector(".min-h-screen");
    if (heroSection) {
      const heroHeight = heroSection.clientHeight;
      const fadeOutStart = heroHeight * 0.6;

      // Only update opacity if we're above the fade out point
      // This prevents the flickering at the bottom of the page
      if (scrollPosition <= fadeOutStart) {
        if (buttonRef.current) {
          gsap.to(buttonRef.current, {
            opacity: 1,
            duration: 0.3,
            ease: "power2.out",
          });
        }
        setShouldShow(true);
      } else {
        if (buttonRef.current) {
          gsap.to(buttonRef.current, {
            opacity: 0,
            duration: 0.3,
            ease: "power2.out",
            onComplete: () => {
              setShouldShow(false);
            },
          });
        }
      }
    }
  }, [scrollPosition]);

  const handleClick = () => {
    if (!buttonRef.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const scaleX = window.innerWidth / buttonRect.width;
    const scaleY = window.innerHeight / buttonRect.height;
    const scale = Math.max(scaleX * 1.1, scaleY * 1.1);

    const centerX =
      window.innerWidth / 2 - (buttonRect.left + buttonRect.width / 2);
    const centerY =
      window.innerHeight / 2 - (buttonRect.top + buttonRect.height / 2);

    if (textRef.current) {
      gsap.to(textRef.current, {
        opacity: 0,
        duration: 0.2,
      });
    }

    if (buttonRef.current) {
      buttonRef.current.style.zIndex = "9999";
    }

    gsap.to(buttonRef.current, {
      duration: 0.6,
      scale: scale,
      x: centerX,
      y: centerY,
      ease: "power2.inOut",
      onComplete: () => {
        setTimeout(() => {
          navigate("/blog/introducing-createsite-ai");
        }, 100);
      },
    });
  };

  if (!shouldShow) return null;

  return (
    <div className="mobile:block tablet:block laptop:hidden desktop:hidden">
      <AnimatePresence>
        <motion.div
          ref={buttonRef}
          className={`fixed z-50 rounded-2xl overflow-hidden mobile:bottom-6 mobile:right-4 tablet:bottom-6 tablet:right-6 laptop:bottom-6 laptop:right-6 desktop:bottom-6 desktop:right-6 ${
            theme === "light" ? "bg-white" : "bg-black"
          } border ${
            theme === "light" ? "border-black/10" : "border-white/10"
          } mobile:mx-1 tablet:mx-8`}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div className="relative">
            <button
              onClick={handleClick}
              className={`w-full text-left p-4 ${
                theme === "light" ? "text-black" : "text-white"
              }`}
            >
              <div className="flex items-center gap-2">
                <span ref={textRef} className="text-sm font-medium">
                  {t("FloatingAnnouncement.title")} 🎉
                </span>
              </div>
            </button>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default FloatingAnnouncement;
