import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import ModalWrapper from "./ModalWrapper";
import ModalContent from "./ModalContent";
import SuccessMessage from "./SuccessMessage";
import EmailForm from "./EmailForm";

interface EmailCaptureModalProps {
  onClose: () => void;
}

const EmailCaptureModal: React.FC<EmailCaptureModalProps> = ({ onClose }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <ModalWrapper onClose={onClose}>
      <ModalContent>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">{t("EmailCaptureModal.title")}</h2>
          <button
            onClick={onClose}
            className={`${
              theme === "light" ? "text-black" : "text-white"
            } hover:opacity-70 transition-colors duration-300`}
          >
            <FaTimes />
          </button>
        </div>
        <EmailForm />
      </ModalContent>
    </ModalWrapper>
  );
};

export default EmailCaptureModal;
