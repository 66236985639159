import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const ProductDemo: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div
      id="demo-section"
      className={`py-20 ${theme === "light" ? "bg-white" : "bg-black"}`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="flex flex-col items-center"
        >
          <h2
            className={`text-4xl md:text-6xl lg:text-7xl mobile:text-3xl tablet:text-6xl laptop:text-7xl desktop:text-7xl font-bold ${
              theme === "light" ? "text-black" : "text-white"
            } mb-16 text-center`}
          >
            Product Demo
          </h2>
          <div
            className="w-full max-w-4xl aspect-video border rounded-lg overflow-hidden"
            style={{ lineHeight: 0 }}
          >
            <mux-player
              playback-id="4005nR01oq00oYUAeHJBrGAWEPIFLWYRFJitmYxV4N00e1w"
              metadata-video-title="Placeholder (optional)"
              metadata-viewer-user-id="Placeholder (optional)"
              accent-color="#FF0000"
              autoplay
              muted
              className="w-full h-full block"
              style={{
                borderColor:
                  theme === "light"
                    ? "rgba(0,0,0,0.1)"
                    : "rgba(255,255,255,0.1)",
              }}
            ></mux-player>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ProductDemo;
